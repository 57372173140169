import { Button, DialogActions, DialogContent, FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import { deleteSegment } from "../../utils/regions/regions";


const ModalIADescription = ({ open, setOpen, onGenerate, segmentId, videoPath, timeSec, segmentDuration, language,setLanguage, tone,setTone }) => {


console.log(language, tone, "language, tone");
console.log(segmentId, videoPath, timeSec, segmentDuration, "segmentId, videoPath, timeSec, segmentDuration");
  const languages = [
    { value: 'English', label: 'English' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Italian', label: 'Italian' }
  ];

  const tones = [
    { value: 'children', label: 'Children' },
    { value: 'documentary', label: 'Documentary' },
    { value: 'fiction', label: 'Fiction' }
  ];

  const handleConfirm = () => {
    onGenerate();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="ai-description-dialog"
    >
      <DialogTitle
        id="ai-description-dialog"
        sx={{ color: "white", backgroundColor: "#28262E" }}
      >
        Generate AI Description
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: "#28262E", pt: 2 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel sx={{ color: "white" }}>Language</InputLabel>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            sx={{ color: "white" }}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.value} value={lang.value}>
                {lang.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel sx={{ color: "white" }}>Tone</InputLabel>
          <Select
            value={tone}
            onChange={(e) => setTone(e.target.value)}
            sx={{ color: "white" }}
          >
            {tones.map((t) => (
              <MenuItem key={t.value} value={t.value}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions
        sx={{
          color: "white",
          backgroundColor: "#28262E",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          sx={{ color: "white", backgroundColor: "#952937" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          sx={{ color: "white", backgroundColor: "#8EC36A" }}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalIADescription;
