import { secondsToSubrip } from "./time/time";
import { Document, Packer, Paragraph, TextRun } from "docx";
import saveAs from "file-saver";

export const segmentsToSRT = (segments) => {
  let srt = "";
  segments.forEach((segment, index) => {
    srt += `${index + 1}\n`;
    const [startHH, startMM, startSS, startMS] = secondsToSubrip(segment.start || segment.startTime);
    const [endHH, endMM, endSS, endMS] = secondsToSubrip(segment.end || segment.endTime);
    srt += `${startHH}:${startMM}:${startSS},${startMS} --> ${endHH}:${endMM}:${endSS},${endMS}\n`;

    srt += `${segment?.description?.initial || segment.text}\n\n`;
  });
  return srt;
};

export const requestSegmentstoSrt = async (currentProject) => {
  let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;

  await fetch(urlPath)
    .then((response) => response.json())
    .then((data) => {
      const segments = data.project.segments.sort((a, b) => a.start - b.start);
      const srt = segmentsToSRT(segments);
      generateBlobSRT(srt,currentProject.name);
      
    });
};
export const requestSegmentstoSrtweb = async (currentProject) => {
  let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;

  await fetch(urlPath)
    .then((response) => response.json())
    .then((data) => {
      const segments = data.project.segments.sort((a, b) => a.start - b.start);
      const srt = segmentsToSRT(segments);
      
      // Crear un blob y abrirlo en una nueva pestaña
      const blob = new Blob([srt], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
};
export const requestSegmentstoSrt2 = async (currentProject) => {
  try {
    let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;

    const response = await fetch(urlPath);
    const data = await response.json();
    
    if (data && data.project) {
      const segments = data.project.segments.sort((a, b) => a.start - b.start);
      const srt = segmentsToSRT(segments);
      return srt; // Devolvemos el contenido SRT
    }
    return null;
  } catch (error) {
    console.error('Error fetching SRT:', error);
    return null;
  }
};
export const generateBlobSRT = (srt,name) => {
  const blob = new Blob([srt], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `${name }.srt`;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
};
export const requestSegmentstoSrtformat = async (currentProject, format) => {
  try {
    let urlPath = `${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`;
    const response = await fetch(urlPath);
    const data = await response.json();
    
    if (!data || !data.project) {
      throw new Error("Invalid project data");
    }

    const segments = data.project.segments.sort((a, b) => a.start - b.start);
    const srt = segmentsToSRT(segments);

    if (format === "srt") {
      generateBlobSRT(srt, currentProject.name);
    } else if (format === "docx") {
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: srt.split("\n").map(line => 
              new Paragraph({
                children: [
                  new TextRun({
                    text: line,
                    font: "Courier New",
                    size: 21, // 10.5 puntos * 2 (docx usa medios puntos)
                  })
                ],
                spacing: {
                  after: 200,
                }
              })
            ),
          },
        ],
      });
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${currentProject.name}.docx`);
      });
    } else {
      console.error("Unsupported format");
    }
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};