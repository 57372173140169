import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DownloadIcon from '@mui/icons-material/Download';
import MovieIcon from '@mui/icons-material/Movie';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CircularProgress, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useState } from 'react';
import { generateAudioFinal } from '../../../utils/audio/generateAudiofinal';
//import { generateAudioVO } from '../../../utils/audio/generateAudioVO';
import { requestSegmentstoSrt } from '../../../utils/export-srt';
import { generateUploadMixAudio } from '../../../utils/audio/generateMix';
import { DataUser } from '../../../context/DataUser';
import { useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { clearAudioFields } from '../../../utils/project/CRUD';
const DownloadResources = ({project,setOpen}) => {
const [openModalDelete, setOpenModalDelete] = useState(false);
const [loading, setLoading] = useState(false)
const [type, setType] = useState('')
const [selectedFile, setSelectedFile] = useState(null);
const { updateRolewithid } = useContext(DataUser);
const downloadLink = async (url, type) => {
  setType(type);
  setLoading(true);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    if (!response.ok) {
      throw new Error(`No se pudo descargar el archivo. Código de estado HTTP: ${response.status}`);
    }

    const blob = await response.blob();
    const fileName = url.split('/').pop();
    const blobUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
      setLoading(false);
    }, 100);
  } catch (error) {
    console.error(error);
    window.open(url, '_blank');
    setLoading(false);
  }
};

const handleFileUpload = async (event) => {
  const file = event.target.files[0];

  if (!file) {
    console.error("❌ No se seleccionó ningún archivo.");
    return;
  }

  console.log("📂 Archivo seleccionado:", file);
  console.log("📤 Iniciando subida...");

  setLoading(true);
  try {
    console.log("📡 Enviando archivo a generateUploadMixAudio...");
  const response = await generateUploadMixAudio(project._id, file);
  await updateRolewithid(project._id, "AE");
  console.log("✅ Respuesta del servidor:", response);


    if (response.error) {
      console.error("❌ Error en la subida:", response.error);
    } else {
     
    }
  } catch (error) {
    console.error("🔥 Error en la carga de audio:", error);
  } finally {
    setLoading(false);
    setOpen(false);
  }
};
const handleopenmodaldelete = () => {
  setOpenModalDelete(true);
}

const handleCloseModalDelete = () => {
  setOpenModalDelete(false);
};

const handleDeleteAudio = async () => {
  setLoading(true);
  try {
    // Aquí iría la llamada a la API para eliminar el audio
    // Por ejemplo: await deleteAudioMix(project._id);
    await clearAudioFields(project._id);
    await updateRolewithid(project._id, "AN");
 
    setOpenModalDelete(false);
    setOpen(false);
  } catch (error) {
    console.error("Error al eliminar el audio:", error);
  } finally {
    setLoading(false);
  }
};

  return (
    <>
      <List style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '1.5rem' }}>
        <Typography
          variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
          <AttachFileIcon /> Download Original Track 
        </Typography>
        <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <MovieIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={project?.name} secondary={project?.video.split('/').pop()} />
          <IconButton aria-label="download" onClick={() => downloadLink(project?.video,'Video') }>
            {(loading && type=='Video') ? <CircularProgress/> : <DownloadIcon />}
          </IconButton>
        </ListItem>
        <Typography
          variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
          <AttachFileIcon />Download SRT AD
        </Typography>
        <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <ArticleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={project?.name} secondary="SRT" />
          <IconButton aria-label="download" onClick={() => requestSegmentstoSrt(project)}>
            <DownloadIcon />
          </IconButton>
        </ListItem>
        <Typography
          variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
          <AttachFileIcon />  {project?.audiomix ? 'Download MIX Records' : 'Upload MIX Records'}
        </Typography>
        <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <AudioFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={project?.audiomix?project?.name:'Audio Records'} secondary={project?.audiomix?.split('/').pop() || 'No audio records'} />
          {project?.audiomix ? (
            <>
            <IconButton aria-label="delete" onClick={() => handleopenmodaldelete()}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="download" onClick={() => downloadLink(project?.audiomix,'Audio Records')}>
              {(loading && type=='Audio Records') ? <CircularProgress/> : <DownloadIcon />}
           
            </IconButton>
            </>
          ) : (
            <>
              <input
                type="file"
                accept="audio/*"
                style={{ display: 'none' }}
                id="upload-audio-mix"
                onChange={handleFileUpload}
              />
              <label htmlFor="upload-audio-mix">
                <IconButton component="span" aria-label="upload">
                  {loading ? <CircularProgress size={24} /> : <CloudUploadIcon />}
                </IconButton>
              </label>
            </>
          )}
        </ListItem>
        <Typography
          variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
          <AttachFileIcon /> Download Final MIX
        </Typography>
        <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <AudioFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={project?.audiofinal? project?.name:'Audio Final'} secondary={project?.audiofinal?.split('/').pop() || 'No final mix'} />
          <IconButton aria-label="generate audiofinal" onClick={()=>generateAudioFinal(project?._id)}  > <RestartAltIcon /> </IconButton>
          <IconButton aria-label="download" onClick={() => downloadLink(project?.audiofinal,'Audio Final') } disabled={!project?.audiofinal}>
            {(loading && type=='Audio Final') ? <CircularProgress/> : <DownloadIcon />}
          </IconButton>
        </ListItem>
        {/*<Typography
          variant="h6" sx={{ color: "white", fontSize: "16px", fontWeight: "normal" ,display: 'flex', alignItems: 'center'}}>
          <AttachFileIcon /> Download Audio VO
        </Typography>
         <ListItem sx={{ mb: 1, backgroundColor: 'var(--black)', borderRadius: '10px',marginTop:'.2rem' }}>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'white' }}>
              <AudioFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={project?.audioVO? project?.name:'Audio VO'} secondary={project?.audioVO?.split('/').pop() || 'No final VO'} />
          <IconButton aria-label="generate audioVO" onClick={()=>generateAudioVO(project?._id)}  > <RestartAltIcon /> </IconButton>
          <IconButton aria-label="download" onClick={() => downloadLink(project?.audioVO,'Audio VO') } disabled={!project?.audioVO}>
            {(loading && type=='Audio VO') ? <CircularProgress/> : <DownloadIcon />}
          </IconButton>
        </ListItem> */}
      </List>

      <Dialog
        open={openModalDelete}
        onClose={handleCloseModalDelete}
        aria-labelledby="alert-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: 'var(--black)',
            color: 'white',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete the audio?"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            This action cannot be undone. The audio final also will be deleted and the project will be set to Narrator role.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalDelete} sx={{ color: 'white' }}>
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteAudio} 
            sx={{ color: 'red' }} 
            autoFocus
          >
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
  
}

export default DownloadResources
