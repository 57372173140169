import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SaveIcon from "@mui/icons-material/Save";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import AssistantIcon from '@mui/icons-material/Assistant';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useContext, useState } from "react";
import { DataDescriptor } from "../../context/DataDescriptor";
import styles from "../../css/BarActions.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { requestSegmentstoSrt, requestSegmentstoSrtweb ,requestSegmentstoSrt2} from "../../utils/export-srt";
import {
  handleNextRegion,
  handlePreviousRegion,
} from "../../utils/controls/controlsSegment";

import { DataNarrator } from "../../context/DataNarrator";
import { putDescriptionSegment, putScriptSegmentSign, updateDescriptionSegment } from "../../utils/descriptions/description";
import ModalDelete from "../describer/ModalDelete";
import { Recorder } from "../describer/Recorder";
import ModalIADescription from "../describer/ModalIADescription";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    //establecer style
    >
      <Paper
        {...props}
        style={{ backgroundColor: "#28262E", color: "white", width: "50%" }}
      />
    </Draggable>
  );
}
export const BarActions = () => {
  const {
    regions,
    websurfer,
    setTimeSubRip,
    currentRegion,
    setActualTime,
    setCurrentRegion,
    setStartHH,
    setStartMM,
    setStartSS,
    setStartMS,
    setEndHH,
    setEndMM,
    setEndSS,
    setEndMS,
    setDuration,
    user,
    setDescription,
    setIsActiveAudio, 
    setCaption, 
    setIsPlaying, 
    setDescriptionTest, 
    viewAllSegments,
    currentProject,
    generateFrameDescription,
    generateFrameDescriptionwithbible,
    fetchSegmentById,
    setWordsCount,
    setCharactersCount,
    setRecommendCharacterMin,
    setRecommendCharacterMax,
    showsrt,
    setShowsrt,
    srt,
    setSrt
  } = useContext(DataDescriptor);
  const { wavesurfer } = useContext(DataNarrator)
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [statusSave, setStatusSave] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);  // 🔹 Estado para manejar el spinner
  const [language, setLanguage] = useState('');  // Changed to empty string
  const [tone, setTone] = useState('');  // Changed to empty string
  console.log(language, tone, "language, tone");
  const [openAIModal, setOpenAIModal] = useState(false);
  const handleClickOpen = () => {
    setDescriptionTest(currentRegion.data?.description?.initial);

    setOpen(true);
  };
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleClickOpenModalDelete = () => {
    setOpenModalDelete(true);
  };

  const handleSaveSegment = async () => {
    currentRegion.update({
      data: {
        ...currentRegion.data,
      },
    });
    const result = window.location.href.includes("script-adapter") ? await putScriptSegmentSign(currentRegion.data, currentRegion.data.id) : await putDescriptionSegment(currentRegion.data, currentRegion.data.id);

    if (result.status) {
      setMessage("Segment saved");
      setStatusSave(true);
      setOpenToast(true);
    } else {
      setMessage("Error saving segment");
      setStatusSave(false);
      setOpenToast(true);
    }
  };
  const handleGenerateFrameDescription = async () => {
    const segmentId = currentRegion?.data?.id;
    const videoPath = currentProject?.video;
    const timeSec = parseFloat(currentRegion?.data?.start);
    const segmentDuration = parseFloat(currentRegion?.data?.end) - parseFloat(currentRegion?.data?.start);
    const bibleid = currentProject?.bible;

    console.log("🚀 Iniciando generación de descripción con IA...");
    console.log("🆔 segmentId:", segmentId);
    console.log("📹 videoPath:", videoPath);
    console.log("⏱ timeSec:", timeSec);
    console.log("⏳ segmentDuration:", segmentDuration);

    if (!segmentId || !videoPath || isNaN(timeSec) || isNaN(segmentDuration)) {
        console.error("❌ Error: Algunos valores están undefined o no son números.");
        return;
    }

    setLoading(true);

    try {
        console.log("📡 Enviando solicitud a `generateFrameDescription`...");
        if (bibleid) {
          // Si hay bibleid, ejecutar una función diferente
          console.log("📡 Enviando solicitud a `generateFrameDescriptionwithbible con biblia`...");
          await generateFrameDescriptionwithbible(segmentId, videoPath, timeSec, segmentDuration,bibleid);
      } else {
          // Si no hay bibleid, ejecutar la función original
          console.log("📡 Enviando solicitud a `generateFrameDescription sin biblia`...");
          if(!language || !tone){
            setLoading(false); // Detenemos el loading antes de mostrar el modal
            setOpenAIModal(true);
            return; // Salir temprano si se abre el modal
          } else {
            await generateFrameDescription(segmentId, videoPath, timeSec, segmentDuration, language, tone);
          }
      }


        console.log("✅ Descripción generada exitosamente.");

        // 🔄 Esperar 1 segundo antes de obtener la nueva información del segmento
        await new Promise(resolve => setTimeout(resolve, 1000));

        console.log("🔄 Recuperando el segmento actualizado desde el Context...");
        const updatedSegment = await fetchSegmentById(segmentId); // 🔹 Usar la función desde el Context

        if (updatedSegment) {
            console.log("📥 Segmento actualizado recibido:", updatedSegment);
            updateDescriptionSegment(
              currentRegion,
              updatedSegment.description.initial,
              updatedSegment.start,
              updatedSegment.end,
              setWordsCount,
              setDuration,      
              setCharactersCount,
              setRecommendCharacterMin,
              setRecommendCharacterMax
            );
           
  
            // Actualizar la descripción en el estado global
            if (updatedSegment?.description?.initial) {
                console.log("✏️ Nueva descripción obtenida:", updatedSegment.description.initial);
                setDescription(updatedSegment.description.initial);
            }
        }

        setMessage("✅ Frame description generated successfully");
        setStatusSave(true);
    } catch (error) {
        console.error("❌ Error generating frame description:", error);
        setMessage("❌ Error generating frame description");
        setStatusSave(false);
    } finally {
        setLoading(false);
        setOpenToast(true);
        console.log("🎯 Finalizando proceso de generación de descripción.");
    }
};

const handleShowSrt = async () => {
  const srtContent = await requestSegmentstoSrt2(currentProject);
  console.log("🔄 Contenido SRT recibido:", srtContent);
  setSrt(srtContent);
  setShowsrt(!showsrt);
 
}

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusSave ? "success" : "error"}>{message}</Alert>
      </Snackbar>
      <div className={styles.actions}>
    
      <button variant="contained" onClick={handleGenerateFrameDescription} disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : <AssistantIcon fontSize="large" />}
        </button>
        <button
          variant="contained"
          onClick={() =>
            handlePreviousRegion(
              regions,
              websurfer,
              setTimeSubRip,
              currentRegion,
              setActualTime,
              setCurrentRegion,
              setStartHH,
              setStartMM,
              setStartSS,
              setStartMS,
              setEndHH,
              setEndMM,
              setEndSS,
              setEndMS,
              setDuration,
              user,
              setDescription,
              setIsActiveAudio,
              setCaption,
              wavesurfer, setIsPlaying, viewAllSegments, currentProject
            )
          }
        >
          
          <SkipPreviousIcon fontSize="large" />
        </button>
        <button
          variant="contained"
          onClick={() =>
            handleNextRegion(
              regions,
              websurfer,
              setTimeSubRip,
              currentRegion,
              setActualTime,
              setCurrentRegion,
              setStartHH,
              setStartMM,
              setStartSS,
              setStartMS,
              setEndHH,
              setEndMM,
              setEndSS,
              setEndMS,
              setDuration,
              user,
              setDescription,
              setIsActiveAudio,
              wavesurfer, setIsPlaying, viewAllSegments, currentProject
            )
          }
        >
          <SkipNextIcon fontSize="large" />
        </button>
        {!window.location.href.includes('script-adapter') && (
          <button onClick={() => handleClickOpen()}>
            <KeyboardVoiceIcon fontSize="large" />
          </button>)}
        <button
          variant="contained"
          onClick={() => handleClickOpenModalDelete()}
          disabled={currentRegion.data?.review?.approved ? true : false}
          className={styles.button}
        >
          <DeleteIcon fontSize="large" />
        </button>
        <button
          variant="contained"
          onClick={() => handleSaveSegment()}
          disabled={currentRegion.data?.review?.approved ? true : false}
          className={styles.button}
        >
          <SaveIcon fontSize="large" />
        </button>
        <button
        variant="contained"
        onClick={() => handleShowSrt()}
        aria-label={currentProject.name + " download srt file"}
        tabIndex={-1}
        style={{ padding: '4px', minWidth: 'auto' }} // 🔹 Ajustar el estilo del botón
      >
        <img
          src="/assets/icons/srt-file-format-symbol.png"
          alt="srt-file-format-symbol icon"
          style={{ width: '25px', height: '25px' }} // 🔹 Ajustar el tamaño de la imagen
        />
      </button>
        <Recorder
          open={open}
          setOpen={setOpen}
          PaperComponent={PaperComponent}
        />
        <ModalDelete open={openModalDelete} setOpen={setOpenModalDelete} />
        <ModalIADescription open={openAIModal} setOpen={setOpenAIModal} onGenerate={handleGenerateFrameDescription} segmentId={currentRegion?.data?.id} videoPath={currentProject?.video} timeSec={currentRegion?.data?.start} segmentDuration={currentRegion?.data?.end} language={language} setLanguage={setLanguage} tone={tone} setTone={setTone} />
      </div>
    </>
  );
};
