import { Button, CircularProgress, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataUser } from "../../context/DataUser";
import { requestSegmentstoSrtformat } from "../../utils/export-srt";

const ModalDownloadSrt = ({ open, setOpen, project }) => {

  const [loading, setLoading] = useState(false);
  console.log("loading",loading);
  const { setProjects, setProjectsOriginal, projectsOriginal } = useContext(DataUser);
  const [format, setFormat] = useState('srt');
  const [statusdownload, setStatusdownload] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      await requestSegmentstoSrtformat(project, format);
      setStatusdownload(true);
      setOpenToast(true);
    } catch (error) {
      setStatusdownload(false);
      setOpenToast(true);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusdownload ? "success" : "error"}>
          {statusdownload ? "File downloaded successfully" : "Error downloading file"}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          Select download format
        </DialogTitle>
        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 3,
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <div style={{ display: 'flex', gap: 2 }}>
                <Button
                  onClick={() => setFormat('srt')}
                  variant={format === 'srt' ? 'contained' : 'outlined'}
                  sx={{ color: "white", borderColor: "white" }}
                >
                  SRT
                </Button>
                <Button
                  onClick={() => setFormat('docx')}
                  variant={format === 'docx' ? 'contained' : 'outlined'}
                  sx={{ color: "white", borderColor: "white" }}
                >
                  DOCX
                </Button>
              </div>
              <div style={{ display: 'flex', gap: 2 }}>
                <Button
                  onClick={() => setOpen(false)}
                  sx={{ color: "white", backgroundColor: "#952937" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDownload}
                  autoFocus
                  sx={{ color: "white", backgroundColor: "#8EC36A" }}
                >
                  Download
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalDownloadSrt;
