import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopIcon from '@mui/icons-material/Stop';
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Snackbar from "@mui/material/Snackbar";
import Mousetrap from "mousetrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { DataDescriptor } from "../../context/DataDescriptor";
import { DataNarrator } from "../../context/DataNarrator";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/Narrator.module.css";
import {
  analysisRecording,
  updateBlobSegment,
} from "../../utils/audio/analysisAudio";
import { generateBlobfromURL } from "../../utils/audio/generateBlob";
import { upladRecordingDubbing, uploadRecordingAudio } from "../../utils/audio/uploadS3";
import { wavesurferScroll } from "../../utils/controls/timeline";
import { fastVolume, slowVolume } from "../../utils/controls/volume";
import { editAnnotation, hideNote, showNote } from "../../utils/descriptions/description";
import { continuePlayAudioRecord, pauseAudioRecord, resetToStart } from "../../utils/time/time";
import { play3secondsPreviusSegment } from "../../utils/time/timeActions";
import StopCircleIcon from '@mui/icons-material/StopCircle';

export const
  AudioRecorderNarator = () => {
    const {
      fullVideo,
      duration,
      currentRegion,
      actualTime,
      volume,
      durationAllVideo,
      websurfer,
      isReset,
      setIsReset,
      setIsPlaying,
      isPlaying,
      stopAudioInWave,
      setCurrentRegionAudio,
      setCaption,
      user,
      setCurrentRegion,
      setStartHH,
      setStartMM,
      setStartSS,
      setStartMS,
      setEndHH,
      setEndMM,
      setEndSS,
      setEndMS,
      setDuration,
      setDescription,
      setFocusDescription,
      setIsActiveAudio,
      isActiveAudio
    } = useContext(DataDescriptor);
    
   
    const { wavesurfer, regionIn, setRegionIn } = useContext(DataNarrator);

    const [isRecording, setIsRecording] = useState(false);
    const [blobURL, setBlobURL] = useState("");
    console.log("blobURL AudioRecorderNarator",blobURL);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const { deviceInput, currentProject, narrationType, deviceOutput } = useContext(DataUser);
    console.log("deviceOutput AudioRecorderNarator",deviceOutput);
    console.log("usando Grabacion de navegador");
    const [openToast, setOpenToast] = useState(false);
    const [statusSave, setStatusSave] = useState("");
    const [message, setMessage] = useState("");
    let progress2 = 0;
    let seekEvent = false;
    const [openCounter, setOpenCounter] = useState(false);
    const [count, setCount] = useState(null);

    // useEffect(() => {
    //   if (!regionIn){
    //           console.log("OOONNNNN READY");
    //           wavesurfer.current.on("ready", readywavesurfer);
    //         }
    // }, []);

    const readywavesurfer = () => {
      if (websurfer.current?.isPlaying()) {

        wavesurfer.current.play()
      }
      else wavesurfer.current.seekTo(progress2);
    }

    const regionOutWavesurfer = (region) => {
      region.update({ seekEvent: false });
      seekEvent = false;
      setIsActiveAudio(false);
      hideNote(region, setCaption);
      //fastVolume(100, websurfer);
    }

    const regionClickWavesurfer = (region) => {
      //readywavesurfer();
      editAnnotation(user,
        region,
        setCurrentRegion,
        setStartHH,
        setStartMM,
        setStartSS,
        setStartMS,
        setEndHH,
        setEndMM,
        setEndSS,
        setEndMS,
        setDuration,
        setDescription
      );
      showNote(region, setCaption);
      //slowVolume(volume, websurfer);
    }
    const regionSeek = (progress) => {
      seekEvent = true;
      let isRegion = false;
      const regionsList = websurfer.current.regions.list;
      let currentRegion2 = null;
      for (const regionId in regionsList) {
        if (regionsList.hasOwnProperty(regionId)) {
          const region = regionsList[regionId];
          const start = region.start;
          const end = region.end;
          const actualTime = progress * durationAllVideo;
          if (actualTime >= start && actualTime <= end) {
            currentRegion2 = region;
            isRegion = true;
            break;
          }
        }
      }
      if (!isRegion) {
        setCurrentRegion(null);
        hideNote(currentRegion, setCaption);
        wavesurferScroll();
      }

      if (!currentRegion2) return;
      const start = currentRegion2?.start;
      const end = currentRegion2?.end;
      const actualTime = progress * durationAllVideo;
      const duration = end - start;
      const time = actualTime - start;
      const seekTo = time / duration;
      if (seekTo >= 0 && seekTo <= 1) {
        progress2 = seekTo;
        if (wavesurfer.current.backend) {
          wavesurfer.current.seekTo(seekTo);
        }
      }
    };

    useEffect(() => {
      if (isReset) {
        stopAudio();
        resetToStart(websurfer, setIsPlaying, stopAudioInWave);
      }
    }, [isReset]);

    useEffect(() => {
      createWave();
    }, []);

    useEffect(() => {
      if (actualTime && currentRegion) {
        const timeVideo = durationAllVideo * actualTime;
        if (timeVideo > currentRegion.end) {
          setIsActiveAudio(false);
          setCurrentRegionAudio(null);
        }
      }
      if (!wavesurfer) return;
      if (currentRegion && !isRecording) {
        createWave();
        if (currentRegion.data.blob) {
          setBlobURL(currentRegion.data.blob);
          wavesurfer.current.load(currentRegion.data.blob);

          wavesurfer.current.un("ready");
          if (!regionIn) wavesurfer.current.on("ready", readywavesurfer);

          websurfer.current.un("region-in");
          if (!regionIn) {
            websurfer.current.on("region-in", regionClickWavesurfer);
          }

          websurfer.current.un("region-out");
          websurfer.current.on("region-out", regionOutWavesurfer);

          websurfer.current.un("region-click");
          websurfer.current.on("region-click", regionClickWavesurfer);

          websurfer.current.un("seek");
          websurfer.current.on("seek", regionSeek);

        } else if (currentRegion.data.audiorecord.initial) {
          generateBlobfromURL(currentRegion.data.audiorecord.initial, wavesurfer, currentRegion);
          wavesurfer.current.on("ready", readywavesurfer);
        }
      } else {
        setBlobURL("");
      }

    }, [currentRegion]);

    useEffect(() => {
      if (isRecording) {
        slowVolume(volume, websurfer);
      }
     /* if (!isRecording && volume !== 100 && !isPlaying) {
       // fastVolume(volume, websurfer);
      }*/

      if (!isRecording) return;
      let timer = setTimeout(() => stopRecording(true), (duration * 1000));
      return () => clearTimeout(timer);
    }, [isRecording]);

    // useEffect(() => {
    //   if (!isRecording) {
    //     console.log("fastVolume 1", volume);
    //     fastVolume(volume, websurfer);
    //   }

    //   if (!isRecording) return;
    //   let timer = setTimeout(() => stopRecording(true), (duration * 1000)+500);
    //   return () => clearTimeout(timer);
    // }, [isRecording]);

    useEffect(() => {
      if (currentRegion && !isActiveAudio) {
        const secondsError = 0.1;
        const timeVideo = durationAllVideo * actualTime;
        if (timeVideo >= (currentRegion.start - secondsError) && timeVideo <= (currentRegion.end + secondsError) && isPlaying) continuePlayAudioRecord(wavesurfer, websurfer, setIsPlaying);
      }
      if (currentRegion && !isPlaying) pauseAudioRecord(wavesurfer, setIsPlaying, setIsActiveAudio);

    }, [isPlaying, currentRegion]);

    const startRecording = (currentRegion) => {
      restartCount();
      setRegionIn(currentRegion)
      //fastVolume(volume, websurfer);
      slowVolume(volume, wavesurfer);
      setIsActiveAudio(true);
      websurfer.current.un("region-in");
      createWave();
      const { duration } = play3secondsPreviusSegment(currentRegion, websurfer, setIsPlaying);
      setTimeout(() => {
        navigator.mediaDevices
          .getUserMedia({ audio: { deviceId: deviceInput.deviceId } })
          .then((stream) => {
            const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
            setMediaRecorder(mediaRecorder);
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            const mediaStreamSource = audioContext.createMediaStreamSource(stream);

            // Create a BiquadFilterNode
            const biquadFilter = audioContext.createBiquadFilter();
            biquadFilter.type = "highpass";
            biquadFilter.frequency.setValueAtTime(80, audioContext.currentTime); // Cutoff at 80Hz (adjust as needed)
            biquadFilter.Q.setValueAtTime(1, audioContext.currentTime); // Set Q factor

            // Second, apply a lowpass filter to reduce high-frequency noise
            const lowpassFilter1 = audioContext.createBiquadFilter();
            lowpassFilter1.type = "lowpass"; // Lowpass filter to remove high-frequency noise
            lowpassFilter1.frequency.setValueAtTime(8000, audioContext.currentTime); // Cutoff at 8kHz (adjust as needed)
            lowpassFilter1.Q.setValueAtTime(1, audioContext.currentTime); // Set Q factor

            // Third, another lowpass filter for further smoothing high-frequency noise
            const lowpassFilter2 = audioContext.createBiquadFilter();
            lowpassFilter2.type = "lowpass"; // Apply another lowpass filter
            lowpassFilter2.frequency.setValueAtTime(8000, audioContext.currentTime); // Same cutoff frequency
            lowpassFilter2.Q.setValueAtTime(1, audioContext.currentTime); // Set Q factor

            // Connect filters and audio source
            mediaStreamSource.connect(biquadFilter); // Connect microphone input to highpass filter
            biquadFilter.connect(lowpassFilter1); // Chain to the first lowpass filter
            lowpassFilter1.connect(lowpassFilter2); // Chain to the second lowpass filter
            lowpassFilter2.connect(analyser); // Output to the destination (speakers or recording)

            const bufferLength = analyser.fftSize;
            const dataArray = new Float32Array(bufferLength);
            const recordingData = [];
            const updateDecibelLevel = () => {
              analyser.getFloatTimeDomainData(dataArray);
              const max = Math.max(...dataArray.map(Math.abs));
              const decibelValue = 20 * Math.log10(max);
              recordingData.push(decibelValue);
              requestAnimationFrame(updateDecibelLevel);
            };
            updateDecibelLevel();
            initMicrophoneAndStart();
            console.time("Start Recording");
           /* mediaRecorder.onstart = () => {
              console.log("Recording started", "duartion: ", duration);
              console.timeEnd("Start Recording");
            }*/
            setIsRecording(true);
            mediaRecorder.start();

            mediaRecorder.addEventListener("dataavailable", async (e) => {
              console.log("Recording stopped");
              const blobAudio = new Blob([e.data], { type: "audio/webm" });
              const blobURLAudio = URL.createObjectURL(blobAudio);
              setBlobURL(blobURLAudio);
              await wavesurfer.current.load(blobURLAudio);
              wavesurfer.current.on("ready", () => validateBlob(blobAudio, blobURLAudio, recordingData));
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }, duration - 200);
    };
    async function initMicrophoneAndStart() {
      try {
        await wavesurfer.current.microphone.init(); // Inicializar el micrófono
        wavesurfer.current.microphone.start(); // Iniciar el micrófono
        setIsRecording(true);
      } catch (error) {
        console.error("Error al inicializar o iniciar el micrófono:", error);
      }
    }

    const stopRecording = () => {
      mediaRecorder.stop();
      fastVolume(volume, websurfer);
      // wavesurfer.current.microphone.stop();
      // wavesurfer.current.microphone.destroy();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setTimeout(() => {
        setRegionIn(null)
        websurfer.current.un("region-in");
        websurfer.current.on("region-in", regionClickWavesurfer);
        setIsRecording(false);
        setIsPlaying(false);
        setIsActiveAudio(false);
      }, 3500);
    };

    const stopRecordingNow = () => {
      mediaRecorder.stop();
      fastVolume(volume, websurfer);
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setRegionIn(null)
      websurfer.current.un("region-in");
      websurfer.current.on("region-in", regionClickWavesurfer);
      setIsRecording(false);
      setIsPlaying(false);
      setIsActiveAudio(false);
      //wavesurfer.current.destroy();
    
      //websurfer.current.seekTo(startTime / websurfer.current.getDuration());
      websurfer.current.pause();
      setCurrentRegion(currentRegion);
    };

    const createWave = () => {
      if (wavesurfer.current) wavesurfer.current?.destroy();
      wavesurfer.current = WaveSurfer.create({
        container: "#waveform2",
        waveColor: "black",
        progressColor: "black",
        cursorColor: "navy",
        height: 100,
        responsive: true,
        backgroundColor: "#fff",
        plugins: [
          // WaveSurferMicrophone.create({
          //   bufferSize: 4096,
          //   numberOfInputChannels: 1,
          //   numberOfOutputChannels: 1,
          //   constraints: {
          //     video: false,
          //     audio: true,
          //   },
          // }),
        ],
      });
      // wavesurfer.current.microphone.on('deviceReady', function (stream) {
      //   console.info('Device ready!', stream);
      // });
      // wavesurfer.current.microphone.on('deviceError', function (code) {
      //   console.error('Device error: ' + code);
      // });

    };

    const validateBlob = async (blob, blobURL, recordingData) => {
      const validateRecording = analysisRecording(recordingData);
      const hasVoiceOver = currentProject?.users?.some((user) => user?.role === 'AN_D');
      if (!validateRecording[0]) {
        wavesurfer.current.setWaveColor("red");
        setOpenToast(true);
        setMessage(validateRecording[1]);
        setStatusSave('error')
      } else {
        wavesurfer.current.setWaveColor("green");
        updateBlobSegment(currentRegion, blobURL, websurfer);
        if (currentProject.service === 'dubbing' || (hasVoiceOver && window.location.href.includes("/narrator-dubbing"))) {
          const audiorecord = await upladRecordingDubbing(currentRegion, currentRegion.data.id, currentRegion.data.user, blob)
          currentRegion.update({
            data: {
              ...currentRegion.data,
              audiorecord: audiorecord,
            },
          });
        } else {
          const audiorecord = await uploadRecordingAudio(currentRegion, currentRegion.data.id, blob)
          currentRegion.update({
            data: {
              ...currentRegion.data,
              audiorecord: audiorecord,
            },
          });
        }
        setOpenToast(true);
        setMessage("Saved Recording");
        setStatusSave("success");
      }
    }
    const audioElementRef = useRef(null);
    const playAudio = async () => {
      setIsActiveAudio(true);
      console.log("playAudio");
      console.log("deviceOutput playAudio", deviceOutput);
  
      // Create a new Audio element if it doesn't exist
      if (!audioElementRef.current) {
        audioElementRef.current = new Audio();
      }
  
      const audioElement = audioElementRef.current;
  
      // If audio is already playing, pause it
      if (!audioElement.paused) {
        audioElement.pause();
        setIsPlaying(false);
        return;
      }
  
      setIsPlaying(true);
  
      // Set the output device if deviceOutput is specified
      if (deviceOutput && 'setSinkId' in audioElement) {
        try {
          await audioElement.setSinkId(deviceOutput.deviceId);
          console.log(`Audio output set to device: ${deviceOutput.label}`);
        } catch (error) {
          console.error('Error setting audio output device:', error);
        }
      } else {
        console.warn('setSinkId is not supported by this browser.');
      }
  
      // Load the audio into the audio element
      if (blobURL) {
        audioElement.src = blobURL; // Use the blob URL directly
        audioElement.play().catch(error => {
          console.error('Error playing audio:', error);
        });
      } else {
        console.error('No valid audio source found.');
      }
  
      // Listen for the end of the audio
      audioElement.onended = () => setIsPlaying(false);
    };
    // todo: ----------------- Aria Countdown -----------------
    
    const handleClickAriaCountdown = async () => {
      // Crear un nuevo elemento de audio
      const audio = new Audio('/audio/speak.mp3');
    
      // Configurar el evento que se ejecuta cuando el audio termina
      audio.addEventListener('ended', () => {
        console.log("end audio");
      });
    
      // Configurar el evento que se ejecuta cuando el audio comienza
      audio.addEventListener('play', () => {
        setOpenCounter(true);
      });
    
      try {
        // Configurar el dispositivo de salida si está definido y compatible
        if (deviceOutput && 'setSinkId' in audio) {
          await audio.setSinkId(deviceOutput.deviceId);
          console.log(`Audio output set to device: ${deviceOutput.label}`);
        } else if (!('setSinkId' in audio)) {
          console.warn('setSinkId is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error setting audio output device:', error);
      }
    
      // Reproducir el audio
      audio.play().catch(error => {
        console.error('Error al reproducir el audio:', error);
      });
    
      console.log("counting");
    };

    // todo: ----------------- Counter -----------------
    // useEffect(() => {
    //   console.log("count", count);
    //   if (count > 0) {
    //     console.log("setVolume useeffect if", volume);
    //     const timer = setTimeout(() => setCount((c) => c - 1), 800);
    //     return () => clearTimeout(timer);
    //   } 
    //   else {
    //     console.log("setVolume useeffect else", volume);
    //     slowVolume(volume, websurfer);
    //     setTimeout(() => setOpenCounter(false), 500);
    //     setFocusDescription(true);
    //   }
    // }, [count]);
    useEffect(() => {
      if (count > 0) {
        const timer = setTimeout(() => setCount((c) => c - 1), 800);
        return () => clearTimeout(timer);
      } else {
        setOpenCounter(false);
        setFocusDescription(true);
      }
    }, [count]);

    const restartCount = () => {
      setCount(3);
    };
    const stopAudio = () => {

      wavesurfer?.current?.pause();
      setIsActiveAudio(false);

      wavesurfer.current.seekTo(0);
      setIsReset(false);
    };

    // todo: ----------------- Keyboard Shortcuts: Start Recording -----------------
    const recordButtonRef = useRef(null);

    const keyboardShorcuts = () => recordButtonRef.current.click();

    const playSegmentShortcut = () => playAudio();

    useEffect(() => {
      Mousetrap.bind(['alt+shift+p', 'option+shift+p'], (e) => {
        playSegmentShortcut();
      })
      return () => Mousetrap.unbind(['alt+shift+p', 'option+shift+p'])
    }, [currentRegion]);

    useEffect(() => {
      Mousetrap.bind(['alt+shift+r', 'option+shift+r'], (e) => {
        keyboardShorcuts();
      })
      return () => Mousetrap.unbind(['alt+o', 'option+o'])
    }, []);

    return (
      <>
        <Snackbar
          open={openToast}
          autoHideDuration={3000}
          onClose={() => setOpenToast(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={statusSave === "success" ? "success" : "error"} variant="filled">
            {message}
          </Alert>
        </Snackbar>
        <div
          className={styles.container__recording}
          style={{
            display: fullVideo ? "none" : "flex",
          }}
        >
          <Dialog
            id='modal-counter'
            open={openCounter}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogContent
              sx={{ color: 'white', backgroundColor: '#28262E' }}
            >
              <DialogContentText
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  /*width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  backgroundColor: '#C75E43',*/
                  margin: '0 auto',
                }}
              >
                <span style={{ fontSize: '48px', color: 'white' }}>
                  {(count === 0) ? "Speak" : count}
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <>
            {!(currentProject.rol?.role === "QC2" || currentProject.rol?.role === "CLIENT2" || window.location.href.includes("/client/")) && (
              <>
                {isRecording ? (
                  <button
                    id="stop-recording"
                    className={styles.btn_stop}
                    onClick={stopRecordingNow}
                    aria-label="Stop Recording"
                  >
                    <StopIcon fontSize="large" />
                  </button>
                ) : (
                  <button
                    id="start-recording"
                    ref={recordButtonRef}
                    className={(currentRegion.data?.review?.approved || isRecording) ? styles.btn_recordDisabled : styles.bg__mic}
                    onClick={() => {
                      startRecording(currentRegion);
                      handleClickAriaCountdown();
                    }}
                    disabled={currentRegion.data?.review?.approved || isRecording}
                    aria-label="Start Recording Narration, Shortcut: Alt + Shift + r"
                  >
                    <KeyboardVoiceIcon fontSize="large" />
                  </button>
                )}
              </>
            )}
          {blobURL && (
  <button
    className={isRecording ? styles.btn_playNarrationDisabled : styles.btn_playNarration}
    onClick={playAudio}
    aria-label={isPlaying ? "Stop Recording Narration" : "Play Recording Narration"}
  >
    {isPlaying ? (
      <StopCircleIcon fontSize="large" />
    ) : (
      <PlayCircleFilledWhiteIcon fontSize="large" />
    )}
  </button>
)}
          </>

          <div className={styles.container}>
            <div className={styles.container_timeline}>
              <div id="waveform2" aria-hidden="true" style={{ position: 'relative' }}></div>
            </div>
          </div>
        </div>
      </>
    );
  };