import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";


const generateMixAudio = async (id) => {
    // {{url}}/api/projects/mix/64ad94894f32f10094c96e77 POST 
    const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/mix/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    });
   
    try {
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(error);
    }finally{
        console.info('end generateMixAudio');
    }
    
};


const s3Client = new S3Client({
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    },
});

export const generateUploadMixAudio = async (id, file) => {
    try {
        if (!file) {
            console.error("❌ No se seleccionó ningún archivo.");
            return { error: "No se seleccionó ningún archivo" };
        }

        console.log("📤 Preparando subida a S3...");

        const key = `${id}/mixRecords-${id}.wav`;
        const params = {
            Bucket: "container-records",
            Key: key,
            Body: file,
            ContentType: "audio/wav",
        };

        const command = new PutObjectCommand(params);
        await s3Client.send(command);

        console.log("✅ Archivo subido a S3:", key);
      
        // Notificar al backend para actualizar MongoDB y ejecutar el script en EC2
        const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/upload-audio-to-s3/${id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ url: `https://container-records.s3.amazonaws.com/${key}` }),
        });

        if (!response.ok) {
            throw new Error(`Error al actualizar el proyecto: ${response.statusText}`);
        }

        console.log("✅ Proyecto actualizado en backend");
        return { success: true, url: `https://container-records.s3.amazonaws.com/${key}` };
    } catch (error) {
        console.error("🔥 Error en generateUploadMixAudio:", error);
        return { error: error.message };
    }
};
export { generateMixAudio };
