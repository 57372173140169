import { Button, CircularProgress, DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useContext, useState } from "react";
import { DataUser } from "../../context/DataUser";
import { requestSegmentstoSrtformat } from "../../utils/export-srt";
import { generateUploadMixAudio } from '../../utils/audio/generateMix';

const ModalUploadToS3 = ({ open, setOpen, project }) => {
  
  const [loading, setLoading] = useState(false);
  const { setProjects, setProjectsOriginal, projectsOriginal, updateRolewithid } = useContext(DataUser);
  const [format, setFormat] = useState("srt");
  const [statusdownload, setStatusdownload] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "audio/wav") {
      setFile(selectedFile);
    } else {
      alert("Please select a valid .wav file");
      setFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }

    setLoading(true);
    try {
      console.log("📡 Enviando archivo a generateUploadMixAudio...");
      const response = await generateUploadMixAudio(project._id, file);
      await updateRolewithid(project._id, "AE");
      console.log("✅ Respuesta del servidor:", response);

      if (response.error) {
        console.error("❌ Error en la subida:", response.error);
      } else {
        setStatusdownload("success");
        setOpenToast(true);
      }
    } catch (error) {
      console.error("🔥 Error en la carga de audio:", error);
      setStatusdownload("error");
      setOpenToast(true);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={statusdownload === "success" ? "success" : "error"}>
          {statusdownload === "success" ? "File uploaded successfully" : "Error uploading file"}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "white", backgroundColor: "#28262E" }}
        >
          Upload the Voiceover
        </DialogTitle>
        <DialogActions
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 3,
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <input type="file" accept="audio/wav" onChange={handleFileChange} />
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                <Button
                  onClick={handleFileUpload}
                  disabled={!file}
                  sx={{ color: "white", backgroundColor: "#8EC36A" }}
                >
                  Upload
                </Button>
                <Button
                  onClick={() => setOpen(false)}
                  sx={{ color: "white", backgroundColor: "#952937" }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalUploadToS3;
