import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { default as WaveSurfer } from "wavesurfer.js";
import * as WaveSurferCursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.js";
import * as WaveSurferRegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js";
import * as WaveSurferTimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.js";
import { addSegmentCut } from "../utils/controls/controlsSegment";
import { wavesurferScroll } from "../utils/controls/timeline";
import {
  editAnnotation,
  hideNote,
  putDescriptionSegment,
  putScriptSegmentSign,
  recommendCharacterCount,
  showNote,
} from "../utils/descriptions/description";
import {
  eachRegions,
  eachRegionsLoop,
  verifiedSegmentsComplete,
  verifiedSegmentsRecordComplete,
  verifiedSegmentsRecordDubbingComplete,
  verifiedSegmentsRecordSignComplete,
  verifiedSegmentsSignComplete,
} from "../utils/regions/regions";
import { putActualTime, secondsToSubrip } from "../utils/time/time";
import { DataUser } from "./DataUser";
import axios from "axios";
export const DataDescriptor = createContext();

export const DataProvider = ({ children }) => {
  const { currentProject, user } = useContext(DataUser);
  //REZISE VARIABLES AND FUNCTIONS
  const [resized, setResized] = useState(false);
  const [fullVideo, setFullVideo] = useState(false);
  const [showsrt, setShowsrt] = useState(false);
  //TIME VARIABLES AND FUNCTIONS
  const minLengthRegion = 1;
  const [startHH, setStartHH] = useState(0);
  const [startMM, setStartMM] = useState(0);
  const [startSS, setStartSS] = useState(0);
  const [startMS, setStartMS] = useState(0);

  const [endHH, setEndHH] = useState(0);
  const [endMM, setEndMM] = useState(0);
  const [endSS, setEndSS] = useState(0);
  const [endMS, setEndMS] = useState(0);
  const [duration, setDuration] = useState(0);
  const [actualTime, setActualTime] = useState(0);
  const [timeSubRip, setTimeSubRip] = useState(0);
  const [durationSubRip, setDurationSubRip] = useState(0);
  const [durationAllVideo, setDurationAllVideo] = useState(0);
  const [isActiveAudio, setIsActiveAudio] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [srt, setSrt] = useState("");
  let startTime = {
    hours: startHH,
    minutes: startMM,
    seconds: startSS,
    milliseconds: startMS,
  };
  let endTime = {
    hours: endHH,
    minutes: endMM,
    seconds: endSS,
    milliseconds: endMS,
  };

  //WORDS DESCRIPTION VARIABLES AND FUNCTIONS
  const [wordsCount, setWordsCount] = useState(0);
  const [charactersCount, setCharactersCount] = useState(0);
  const [recommendCharacterMin, setRecommendCharacterMin] = useState(0);
  const [recommendCharacterMax, setRecommendCharacterMax] = useState(0);
  const [description, setDescription] = useState({});
  const [descriptionTest, setDescriptionTest] = useState(null);
  const [comments, setComments] = useState("");
  const [caption, setCaption] = useState("");
  const [dataLength, setDataLength] = useState(0);

  //SEGMENTS VARIABLES AND FUNCTIONS
  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState(null);
  const [currentRegionAudio, setCurrentRegionAudio] = useState(null);
  const [viewAllSegments, setViewAllSegments] = useState(false);
  //WEB SURFER VARIABLES AND FUNCTIONS
  const websurfer = useRef(null);
  const [waveformLoaded, setWaveformLoaded] = useState(false);
  const [stateTimeline, setStateTimeline] = useState(false);
  const [segmentsComplete, setSegmentsComplete] = useState(false);

  //CONTROL VARIABLES AND FUNCTIONS
  const [zoomLevel, setZoomLevel] = useState(73.4);
  const [loadingZoom, setLoadingZoom] = useState({
    type: "",
    state: false,
  });
  const [loadingWave, setLoadingWave] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);
  const [speeds, setSpeeds] = useState([0.5, 0.75, 1, 1.25, 1.5, 2]);
  const [showAudioDescription, setShowAudioDescription] = useState(true);
  const [percent, setPercent] = useState(0);
  const [isPlayingAction, setIsPlayingAction] = useState(false);
  const handleSpaceRef = useRef();
  const [actionPlay, setActionPlay] = useState(false);
  const [focusDescription, setFocusDescription] = useState(false);

  const audioRef = useRef(null);

  const [clientRevision, setClientRevision] = useState({});

  useEffect(() => {
    createAll();
  }, []);

  useEffect(() => {
    websurfer.current.on("audioprocess", () => {
      let time = websurfer.current.getCurrentTime();
      let timeRange = time / websurfer.current.getDuration();
      setActualTime(timeRange);
      setTimeSubRip(secondsToSubrip(time));
    });
  }, []);

  const changeViewAllSegments = () => {
    setViewAllSegments(!viewAllSegments);
    //ocultar las regiones que este aproved
    const regions = Object.values(websurfer.current.regions.list);
    if (!viewAllSegments) {
      regions.forEach((region) => {
        if (window.location.href.includes('audio-descriptor')) {
          if (!(!region.data.review.approved && (region.data.description?.initial === region.data.description.preview || region.data.description?.initial === ''))) {
            const regionElement = document.querySelector(`region[data-id="${region.id}"]`);
            regionElement.style.display = 'none';
          }
        } else if (window.location.href.includes('narrator')) {
          if (!(!region.data.review.approved && (region.data.audiorecord?.initial === region.data.audiorecord.preview || region.data.audiorecord?.initial === ''))) {
            const regionElement = document.querySelector(`region[data-id="${region.id}"]`);
            regionElement.style.display = 'none';
          }
        } else if (window.location.href.includes('quality-control')) {
          if (!((region.data.review.comment[0] === region.data.review.comment[1]) && !region.data.review.approved)) {
            const regionElement = document.querySelector(`region[data-id="${region.id}"]`);
            regionElement.style.display = 'none';
          }
        }
      })

    } else {
      regions.forEach((region) => {
        const regionElement = document.querySelector(`region[data-id="${region.id}"]`);
        regionElement.style.display = 'block';
      })

    }
  }

  const createAll = () => {
    console.log("CREATE ALL");
    if (websurfer.current === null) {
      const objectCreate = {
        container: "#waveform",
        waveColor: "#777082",
        progressColor: "white",
        cursorColor: "white",
        backend: "MediaElement",
        height: 138,
        responsive: true,
        backgroundColor: "#332D36",
        drag: true,
        scrollParent: false,
        waveHeight: 200,
        barWidth: 2,
        barRadius: 2,
        cursorWidth: 1,
        plugins: [
          WaveSurferRegionsPlugin.create({
          }),
          WaveSurferTimelinePlugin.create({
            container: "#waveform-timeline",
            drag: true,
            scrollParent: false,
            //styles
            primaryColor: "#fff",
            secondaryColor: "#fff",
            primaryFontColor: "#fff",
            secondaryFontColor: "#fff",
            primaryLabelInterval: 1,
            secondaryLabelInterval: 1,
            primaryGridInterval: 1,
            secondaryGridInterval: 1,
            primaryGridColor: "#fff",
            secondaryGridColor: "#fff",
            primaryGridOpacity: 0.5,
            secondaryGridOpacity: 0.5,
            primaryLabelAlign: "bottom",
            secondaryLabelAlign: "bottom",
            primaryLabelFormatter: (time) => {
              return `${time}`;
            },
            secondaryLabelFormatter: (time) => {
              return `${time}`;
            },
          }),
          WaveSurferCursorPlugin.create({
            showTime: true,
            opacity: 1,
            color: "#fff",
            customShowTimeStyle: {
              "background-color": "#000",
              color: "#fff",
              padding: "2px",
              "font-size": "10px",
            },

            customShowTimeFunction: (time) => {
              let seconds = Math.floor(time % 60);
              let minutes = Math.floor((time / 60) % 60);
              let hours = Math.floor((time / 3600) % 24);

              if (hours < 10) {
                hours = "0" + hours;
              }
              if (minutes < 10) {
                minutes = "0" + minutes;
              }
              if (seconds < 10) {
                seconds = "0" + seconds;
              }
              return `${hours}:${minutes}:${seconds}`;
            },
          }),
        ],
      };

      // si tiene rol QC2 QUITAR mediaelement
      if ((currentProject.rol?.role === "QC2" && currentProject.service !== "dubbing" ) || currentProject.rol?.role === "CLIENT2" ) {
        delete objectCreate.backend;
      }


      websurfer.current = WaveSurfer.create(objectCreate);
      createWaveform();
    }
  };

  const setActionsWaveform = () => {
    websurfer.current.on("region-created", (region) => {
      let isEditable = true;
      let addColor = "#12B4B899";

      if (
        (currentProject.rol?.role === "QC2"&& currentProject.service !== "dubbing") ||
        window.location.href.includes("/narrator")
      ) {
        isEditable = false;
      }
      if (region.data?.review?.approved && currentProject.rol?.role === "AD") {
        addColor = "#CCCCCC99";
        isEditable = false;
      }
      if (window.location.href.includes("interpreter")) {

        if (region.data?.isMark) {
          region.update({ color: "#000" });
        }
      }
      region.color = addColor;
      // region.maxLength = 10;// 10 segundos
      region.minLength = (currentProject.service === "dubbing" || currentProject.service === "sign-language-caption") ? 0 : minLengthRegion;
      region.loop = false;
      region.drag = isEditable;
      region.resize = isEditable;
      region.isUpdate = false;
      if (!region?.data?.isMark) {
        setRegions((oldRegions) => [...oldRegions, region]);
        eachRegionsLoop(currentProject.rol?.role, region);
      }
    });
    websurfer.current.on("seek", function (progress) {
      let isRegion = false;
      let progressSeconds = progress * websurfer.current.getDuration();
      const keys = Object.keys(websurfer.current.regions.list);
      keys.forEach((key) => {
        const region = websurfer.current.regions.list[key];

        if (
          progressSeconds >= region.start &&
          progressSeconds <= region.end &&
          region.data.id
        ) {
          isRegion = true;
        }
      });

      if (!isRegion) {
        setCurrentRegion(null);
        hideNote(currentRegion, setCaption);
        wavesurferScroll();
      }
    });

    websurfer.current.on("region-click", (region) => {
      editAnnotation(
        user,
        region,
        setCurrentRegion,
        setStartHH,
        setStartMM,
        setStartSS,
        setStartMS,
        setEndHH,
        setEndMM,
        setEndSS,
        setEndMS,
        setDuration,
        setDescription
      );
      if (region.data?.review?.approved && window.location.href.includes("audio-descriptor")) {
        region.update({
          drag: false,
          resize: false,
        });
      }

      if (window.location.href.includes("/narrator") || window.location.href.includes("/client")) {
        region.update({
          drag: false,
          resize: false,
        });
        showNote(region, setCaption);

      } else {
        hideNote(region, setCaption);
      }
    });
    websurfer.current.on("region-update-end", function (region) {
      const regions = Object.values(websurfer.current.regions.list);

      

      const isIntersecting = regions.some((regionItem) => {
        if (regionItem.id !== region.id) {
          return (
            (region.start <= regionItem.start && region.end >= regionItem.start) ||
            (regionItem.start <= region.start && regionItem.end >= region.start)
          );
        }
        return false;
      });

      if (isIntersecting) {
        region.update({
          start: region.data.start,
          end: region.data.end,
        });
        return;

      } else {

        const data = {
          ...region.data,
          start: region.start,
          end: region.end,
        };

        region.update({
          start: region.start,
          end: region.end,
          data,
        });
        region.isUpdate = true;

        const start = secondsToSubrip(region.start);
        const end = secondsToSubrip(region.end);
        setStartHH(start[0]);
        setStartMM(start[1]);
        setStartSS(start[2]);
        setStartMS(start[3]);
        setEndHH(end[0]);
        setEndMM(end[1]);
        setEndSS(end[2]);
        setEndMS(end[3]);
        setDuration(region.end - region.start);
        setCurrentRegion(region);
        if (window.location.href.includes("script-adapter")) {
          putScriptSegmentSign(data, region.data.id)
        } else {
          putDescriptionSegment(data, region.data.id);
        }
      }
    });
    websurfer.current.on("region-in", (region) => {
      if (!region.data?.isMark) {
        const audioRecord = region.data?.audiorecord?.initial ? region.data?.audiorecord?.initial : region.data?.audiorecord?.initial;
        const audio = new Audio(audioRecord);

        audioRef.current = audio;
        setCurrentRegionAudio(region);
        showNote(region, setCaption);
        editAnnotation(
          user,
          region,
          setCurrentRegion,
          setStartHH,
          setStartMM,
          setStartSS,
          setStartMS,
          setEndHH,
          setEndMM,
          setEndSS,
          setEndMS,
          setDuration,
          setDescription
        );
      }
    });

    websurfer.current.on("region-out", (region) => {
      setCurrentRegionAudio(null);
      audioRef.current = null;
    });
  }

  const cutRegion = async (region) => {
    const { start, end } = region;
    const currentTime = websurfer.current.getCurrentTime();

    if (currentTime > start && currentTime < end) {
      if ((currentTime - start < minLengthRegion) || (end - currentTime < minLengthRegion)) return false;
      const data = {
        ...region.data,
        start: region.start,
        end: currentTime,
      }
      region.update({
        start: region.start,
        end: currentTime,
        data: data,
      });

      if (window.location.href.includes("script-adapter")) {
        await putScriptSegmentSign(data, region.data.id)
      } else {
        await putDescriptionSegment(data, region.data.id);
      }
      const newTime = {
        start: currentTime,
        end: end,
      }
      //crear un nuevo region con el nuevo start
      const resp = await addSegmentCut(newTime, currentProject._id);
      if (resp) {
        const { start, end } = resp;
        websurfer.current.addRegion({
          start: start,
          end: end,
          data: {
            id: resp._id,
            description: resp.description,
            comment: resp.comments,
            start: resp.start,
            end: resp.end,
            review: resp.review,
          },
          color: "#12B4B899",
        });
        return true;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (isActiveAudio === false && currentRegionAudio && !currentRegion) {
      playAudioInWave(currentRegionAudio);
    }
  }, [currentRegionAudio]);



  const playAudioInWave = async () => {
    if (audioRef.current) {
      // audioRef.current.play();
      setIsActiveAudio(true);
    }
  }

  const stopAudioInWave = () => {
    if (audioRef.current) {

      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsActiveAudio(false);
      setCurrentRegionAudio(null);
    }
    setIsReset(false);
  }

  const pauseAudioInWave = () => {
    if (audioRef.current) {

      audioRef.current.pause();
      setIsActiveAudio(false);
    }
  }

  const continuePlayAudioInWave = () => {
    if (audioRef.current) {
      // audioRef.current.play();
      setIsActiveAudio(true);
    }
  }

  const createWaveform = async () => {
    websurfer.current.disableDragSelection();
    if (regions.length > 0) websurfer.current.clearRegions();
    setWaveformLoaded(false);
    const file = ((currentProject.rol.role === "QC2" && currentProject.service != "dubbing") ||currentProject.rol?.role === "CLIENT2") ? currentProject.audiofinal : document.querySelector("video");
    try {
      await websurfer.current.load(file);
    } catch (e) {
    }

    websurfer.current.on("loading", (percent) => {
      setPercent(percent);
    });
    if (file?.src) readyWaveform(file)
    else readyWaveform(file);

  };

  const readyWaveform = async (file) => {
    setActionsWaveform();
    websurfer.current.zoom(zoomLevel);
    if (!waveformLoaded) {
      await websurfer.current.load(file);
    }

    if ((currentProject.rol.role === "QC2" && currentProject.service != "dubbing" ) || currentProject.rol.role === "CLIENT2") {
      websurfer.current.on("ready", async () => {
        const video = document.querySelector("video");
        video.muted = true;
        setStateTimeline(true);
        setWaveformLoaded(true);
        setDurationSubRip(secondsToSubrip(websurfer.current.getDuration()));
        setDurationAllVideo(websurfer.current.getDuration());
        await eachRegions(currentProject, user, websurfer, setDataLength);
      });
    } else {
      websurfer.current.on("waveform-ready", async () => {
        setStateTimeline(true);
        setWaveformLoaded(true);
        setDurationSubRip(secondsToSubrip(websurfer.current.getDuration()));
        setDurationAllVideo(websurfer.current.getDuration());
        await eachRegions(currentProject, user, websurfer, setDataLength);
      });
          }

    websurfer.current.on("seek", (progress) => {
      setActualTime(progress);
      setTimeSubRip(
        secondsToSubrip(progress * websurfer.current.getDuration())
      );
      if ((currentProject.rol?.role === "QC2" && currentProject.service !== "dubbing") || currentProject.rol?.role === "CLIENT2") {
        const video = document.querySelector("video");
        video.currentTime = progress * websurfer.current.getDuration();
      }
    });
  }
  useEffect(() => {
    // Here is where we change colors of regions AD
    if (regions.length > 0) {
      if (!window.location.href.includes("quality-control")) {
        const icons = document.querySelectorAll(".loop");
        if (currentRegion) {
          const newRegions = regions.map((region) => {
            const icon = document.querySelector(`region[data-id="${region.id}"] .loop`);
            region.update({ drag: false });
            if (region.id === currentRegion.id) {
              region.update({ color: "rgba(255, 199, 0, 0.3)" });
              icon.style.background = "rgba(255, 199, 0)";

            } else if (!region.data?.description?.initial && !region.data.comment) {
              region.update({ color: "#12B4B899" });
            } else if (!region.data?.description?.initial && region.data.comment) {
              region.update({ color: "#12B4B899" });
            } else if (
              (region.data?.description?.initial !== region.data.description.preview) || region.isUpdate
            ) {
              region.update({ color: "#8EC36A99" });
            } else {
              region.update({ color: "#12B4B899" });
            }


            if (region.data?.review?.approved) {
              region.update({ color: "#CCCCCC99", drag: false });

            }
            if (window.location.href.includes("/narrator/")) {
              if (region.data?.audiorecord?.initial && region.data?.audiorecord?.initial !== region.data?.audiorecord?.preview) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }
              if (region.data?.review?.approved) {
                region.update({ color: "#CCCCCC99", drag: false });
              }

              if (region.id === currentRegion.id) {
                region.update({ color: "rgba(255, 199, 0, 0.3)" });
              }

            }
            if (window.location.href.includes("/narrator-dubbing/")) {
              if (region.data?.audiorecord?.initial && region.data?.audiorecord?.initial !== region.data?.audiorecord?.preview) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }
              if (region.data?.review?.approved) {
                region.update({ color: "#CCCCCC99", drag: false });
              }

              if (region.id === currentRegion.id) {
                region.update({ color: "rgba(255, 199, 0, 0.3)" });
              }

            }
            if (window.location.href.includes("script-adapter")) {
              if (region.data?.script) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }

              if (region.id === currentRegion.id) {
                region.update({ color: "rgba(255, 199, 0, 0.3)" });
              }

            }
            if (window.location.href.includes("interpreter")) {
              if (region.data?.videorecord || region.data?.blob) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }

              if (region.id === currentRegion.id) {
                region.update({ color: "rgba(255, 199, 0, 0.3)" });
              }
              if (region.data?.isMark) {
                region.update({ color: "#000" });
              }
            }


            return region;
          });

          setRegions(newRegions);
          setDescription(currentRegion.data.description || "");
          setComments(currentRegion.data.comment || "");
        } else {
          icons.forEach((icon) => icon.style.background = "rgba(0, 0, 0,0)");
          const newRegions = regions.map((region) => {
            region.update({ drag: false });
            if (!region.data?.description?.initial && !region.data.comment) {
              region.update({ color: "#12B4B899" });
            } else if (!region.data?.description?.initial && region.data.comment) {
              region.update({ color: "#12B4B899" });
            } else if (
              (region.data?.description?.initial !== region.data.description.preview) || region.isUpdate
            ) {
              region.update({ color: "#8EC36A99" });
            } else {
              region.update({ color: "#12B4B899" });
            }

            if (region.data?.review?.approved) {
              region.update({ color: "#CCCCCC99", drag: false });
            }
            if (window.location.href.includes("/narrator/")) {
              if (region.data?.audiorecord?.initial && region.data?.audiorecord?.initial !== region.data?.audiorecord?.preview) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }
              if (region.data?.review?.approved) {
                region.update({ color: "#CCCCCC99", drag: false });
              }

            }
            if (window.location.href.includes("/narrator-dubbing/")) {
              if (region.data?.audiorecord?.initial && region.data?.audiorecord?.initial !== region.data?.audiorecord?.preview) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }
              if (region.data?.review?.approved) {
                region.update({ color: "#CCCCCC99", drag: false });
              }

            }
            if (window.location.href.includes("script-adapter")) {
              if (region.data?.script) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }

            }
            if (window.location.href.includes("interpreter")) {
              if (region.data?.videorecord || region.data?.blob) {
                region.update({ color: "#8EC36A99" });
              } else {
                region.update({ color: "#12B4B899" });
              }
              if (region.data?.isMark) {
                region.update({ color: "#000" });
              }

            }

            return region;
          });
          setRegions(newRegions);
        }
      }
    }
  }, [currentRegion]);

  useEffect(() => {
    let isComplete = false;
    if (regions.length > 0 && window.location.href.includes('descriptor')) {
      isComplete = verifiedSegmentsComplete(websurfer);
    } else if (window.location.href.includes('/narrator/')) {
      isComplete = verifiedSegmentsRecordComplete(websurfer);
    } else if (window.location.href.includes('/narrator-dubbing/')) {
      isComplete = verifiedSegmentsRecordDubbingComplete(websurfer);
    } else if (window.location.href.includes('script-adapter')) {
      isComplete = verifiedSegmentsSignComplete(websurfer);
    } else if (window.location.href.includes('interpreter')) {
      isComplete = verifiedSegmentsRecordSignComplete(websurfer);
    }
    setSegmentsComplete(isComplete);

    if (currentRegion && !window.location.href.includes("narrator-dubbing")) {
      recommendCharacterCount(
        currentRegion.data?.description?.initial,
        duration,
        setCharactersCount,
        setRecommendCharacterMin,
        setRecommendCharacterMax
      );
    }
  }, [regions, description, duration, currentRegion]);

  useEffect(() => {
    //console.log("slowVolume",volume);
     websurfer.current.setVolume(volume / 100); }, [volume]);

  const playPauseAfter3Seconds = useCallback(
    async (endTime) => {
      if (websurfer.current?.isPlaying()) {

        websurfer.current.pause();
        setIsPlaying(false);

      } else {
        const startTime = websurfer.current.getCurrentTime();
        await websurfer.current.play(startTime, endTime);
        setIsPlaying(true);

      }
      return websurfer.current.getDuration();
    },
    [isPlayingAction]
  );

  const playPause = useCallback(async () => {
    if (isPlayingAction) return; // Evita que la función se llame varias veces
    setIsPlayingAction(true);

    if (await websurfer.current?.isPlaying()) {
      websurfer.current.pause();
      if ((currentProject.rol.role === "QC2" && currentProject.service != "dubbing") ||currentProject.rol?.role === "CLIENT2") {
        const video = document.querySelector("video");
        video.pause();
      }

      if (!currentRegion) {
        pauseAudioInWave();
      }

      setIsPlaying(false);

    } else {
      await websurfer.current.play();
      if ((currentProject.rol.role === "QC2"&& currentProject.service != "dubbing") ||currentProject.rol?.role === "CLIENT2") {
        const video = document.querySelector("video");
        video.play();
      }
      if (!currentRegion) {
        continuePlayAudioInWave();
      }
      setIsPlaying(true);
    }

    setIsPlayingAction(false);
  }, [websurfer, setIsPlaying, isPlayingAction, currentRegion, currentRegionAudio]);

  useEffect(() => {

    handleSpaceRef.current = (e) => {
      if (e.key === " ") {
        // Verifica si el elemento activo es un input o textarea
        if (
          document.activeElement.tagName === "INPUT" ||
          document.activeElement.tagName === "TEXTAREA"
        ) {
          return;
        }
        e.preventDefault();
        //toggle estado de play/pause
        setIsPlayingAction(!isPlayingAction);

        playPause();
      }
    };

    document.addEventListener("keydown", handleSpaceRef.current);

    return () => {
      document.removeEventListener("keydown", handleSpaceRef.current);
    };
  }, [playPause]);


  // useEffect(() => {
  //   (async () => {
  //     const response = await fetch(`${process.env.REACT_APP_URL}/api/projects/${currentProject._id}`, { method: "GET" });
  //     const result = await response.json();
  //     setClientRevision({
  //       AD: result?.project?.client[0],
  //       AN: result?.project?.client[1],
  //     });
  //     console.log({
  //       AD: result?.project?.client[0],
  //       AN: result?.project?.client[1],
  //     });
  //   })();
  // }, [currentRegion]);
  const handleSetLoadingZoom = (type, state) => {
    setLoadingZoom({
      type: type,
      state: state,
    })
  }
  const generateFrameDescription = async (segmentId, videoPath, timeSec, segmentDuration,language,tone) => {
    return axios.post(`${process.env.REACT_APP_URL}/api/projects/generate-frame-description2/${segmentId}`, {
        videoPath,
        timeSec,
        segmentDuration,
        language,
        tone
      }
    )
    .then(res => {
      console.log("Response from generateFrameDescription:", res.data); // Muestra la respuesta en la consola
      return res.data;
    })
    .catch(error => {
      console.error("Error generating frame description:", error);
      throw error;
    });
  };
  const generateFrameDescriptionwithbible = async (segmentId, videoPath, timeSec, segmentDuration,bibleid) => {
    return axios.post(`${process.env.REACT_APP_URL}/api/projects/generate-frame-description-with-bible/${segmentId}`, {
        videoPath,
        timeSec,
        segmentDuration,
        bibleid
      }
    )
    .then(res => {
      console.log("Response from generateFrameDescription:", res.data); // Muestra la respuesta en la consola
      return res.data;
    })
    .catch(error => {
      console.error("Error generating frame description:", error);
      throw error;
    });
  };
  const fetchSegmentById = async (segmentId) => {
    try {
        console.log(`📡 Obteniendo segmento con ID: ${segmentId}...`);

        const response = await fetch(`${process.env.REACT_APP_URL}/api/segments/segment/${segmentId}`);
        if (!response.ok) {
            throw new Error(`Error en la solicitud: ${response.statusText}`);
        }

        const segment = await response.json();

        if (!segment) {
            console.warn("⚠️ No se encontró el segmento.");
            return null;
        }

        console.log("✅ Segmento obtenido:", segment);

        return segment;
    } catch (error) {
        console.error("❌ Error al obtener el segmento:", error);
        return null;
    }

};

  return (
    <DataDescriptor.Provider
      value={{
        startTime,
        endTime,
        currentRegion,
        setCurrentRegion,
        setStartHH,
        setStartMM,
        setStartSS,
        setStartMS,
        setEndHH,
        setEndMM,
        setEndSS,
        setEndMS,
        setDuration,
        duration,
        waveformLoaded,
        zoomLevel,
        playPause,
        setComments,
        setDescription,
        description,
        comments,
        isPlaying,
        setTimeSubRip,
        setVolume,
        volume,
        wordsCount,
        actualTime,
        setActualTime,
        timeSubRip,
        durationSubRip,
        putActualTime,
        charactersCount,
        recommendCharacterMin,
        recommendCharacterMax,
        regions,
        percent,
        websurfer,
        setRecommendCharacterMin,
        setRecommendCharacterMax,
        resized,
        setResized,
        fullVideo,
        setFullVideo,
        segmentsComplete,
        playPauseAfter3Seconds,
        durationAllVideo,
        setZoomLevel,
        startHH,
        startMM,
        startSS,
        startMS,
        endHH,
        endMM,
        endSS,
        endMS,
        currentProject,
        setRegions,
        user,
        setWordsCount,
        setCharactersCount,
        setIsPlaying,
        caption,
        isActiveAudio,
        setIsActiveAudio,
        setActionPlay,
        stopAudioInWave,
        isReset,
        setIsReset,
        setCurrentRegionAudio,
        currentRegionAudio,
        showAudioDescription,
        setShowAudioDescription,
        speeds,
        pauseAudioInWave,
        setCaption,
        clientRevision,
        setClientRevision,
        focusDescription,
        setFocusDescription,
        readyWaveform,
        cutRegion,
        descriptionTest,
        setDescriptionTest,
        loadingZoom,
        handleSetLoadingZoom,
        loadingWave, setLoadingWave,
        changeViewAllSegments, viewAllSegments, dataLength,minLengthRegion,
        generateFrameDescription,
        fetchSegmentById,
        showsrt,
        setShowsrt,
        srt,
        setSrt,
        generateFrameDescriptionwithbible
      }}
    >
      {children}
    </DataDescriptor.Provider>
  );
};
